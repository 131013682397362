<template>
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <template v-if="!getIsFullColour">
      <defs>
        <linearGradient :id="'gradient' + uniqueId">
          <stop offset="0%" />
          <stop :offset="percentageFill - 1 + '%'" />
          <stop :offset="percentageFill + '%'" />
          <stop :offset="'100%'" />
        </linearGradient>
      </defs>
    </template>
    <path
      d="M7.90593 1.26087C7.9377 1.17277 8.0623 1.17277 8.09407 1.26087L9.90491 6.28256C9.91881 6.32112 9.95483 6.34728 9.99579 6.34859L15.3313 6.51901C15.4249 6.522 15.4634 6.6405 15.3894 6.69794L11.1731 9.97194C11.1407 9.99708 11.127 10.0394 11.1384 10.0788L12.6251 15.2058C12.6511 15.2957 12.5503 15.369 12.4728 15.3164L8.05617 12.3181C8.02226 12.2951 7.97774 12.2951 7.94383 12.3181L3.52716 15.3164C3.44967 15.369 3.34887 15.2957 3.37495 15.2058L4.86163 10.0788C4.87304 10.0394 4.85929 9.99708 4.82692 9.97194L0.610581 6.69794C0.536607 6.6405 0.57511 6.522 0.66872 6.51901L6.00421 6.34859C6.04517 6.34728 6.08119 6.32112 6.09509 6.28256L7.90593 1.26087Z"
      stroke="black"
      :fill="
        getIsFullColour
          ? getStyles[`COLOUR_bleach_black`]
          : `url(#gradient${uniqueId})`
      "
    />
  </svg>
</template>

<script>
import styles from "@/styles/settings.scss"

export default {
  props: ["percentageFill", "uniqueId"],

  // data: () => ({
  //   styles
  // }),

  computed: {
    getIsFullColour() {
      return this.percentageFill == 100
    },

    getStyles() {
      return styles
    }
  }
}
</script>
